import React from 'react';
import './Mogelijkheden.css';
import MogelijkhedenBigScreen from './mogelijkheden-big-screen';
import MogelijkhedenSmallScreen from './mogelijkheden-small-screen';

class Mogelijkheden extends React.Component {
    render() {
        return(
            <div className="mogelijkheden page-container" id="mogelijkheden">
                <p className='p-header'>Mogelijkheden</p>
                <p className='intro'><b>Omdat alles maatwerk is bij thuiskok Zwaneveld zijn alle prijzen op aanvraag.</b></p>
                <div className="big-screen">
                    <MogelijkhedenBigScreen></MogelijkhedenBigScreen>
                </div>
                <div className="small-screen">
                    <MogelijkhedenSmallScreen></MogelijkhedenSmallScreen>
                </div>
            </div>
        )
    }
}

export default Mogelijkheden;