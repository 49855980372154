import React from 'react';
import './Intro.css'

class Intro extends React.Component {
    render() {
        return(
            <div className="site-intro page-container">
                <p className='p-header header-red'><b>Culinaire mogelijkheden</b> voor elke gelegenheid</p>
                <div className="container">
                    <p>
                    Heeft u iets te vieren? Of wilt u zomaar een gezellige avond met familie of vrienden? Thuiskok Zwaneveld biedt u allerlei mogelijkheden. Wat dacht u van een kookworkshop? Met een groep koken om er daarna met elkaar van te genieten. Of hapjes maken en aansluitend gezellig borrelen.
                    </p>

                    <p>
                        U kunt ook kiezen voor een thuisdiner. Het is heel bijzonder om gasten thuis te ontvangen aan uw eigen tafel met uw mooie servies. Maar dan zonder kookstress en met maximale aandacht voor uw gasten.
                    </p>

                    <p>
                        Verder zijn allerlei vormen van catering mogelijk: luxe hapjes, brunch, verjaardagspartij, high tea, warm en koud buffet. Maatwerk en persoonlijke benadering zijn kenmerkend voor de dienstverlening van thuiskok Zwaneveld.
                    </p>

                    <p>
                        <b>Graag tot ziens in uw keuken of op locatie!</b>
                    </p>
                </div>
            </div>
        )
    }
}

export default Intro;