import './App.css';
import React from 'react';
import Home from './components/home/Home';
import Intro from './components/intro/Intro';
import Mogelijkheden from './components/mogelijkheden/Mogelijkheden';
import ImageContainer from './components/image-container/image-container';
import ImageContainerTafelImage from './assets/4-foto-tafel.jpg';
import Gerechten from './components/gerechten/gerechten';
import CulinaireVerwennerij from './components/culinaire-verwennerij/culinaire-verwennerij';
import OverMij from './components/over-mij/over-mij';
import Weblinks from './components/weblinks/weblinks';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import Navigation from  './components/navigation/navigation';

class Main extends React.Component {
    constructor(props) {
        super(props)
        this.homeRef = React.createRef()
        this.mogelijkhedenRef = React.createRef()
        this.gerechtenRef = React.createRef()
        this.overMijRef = React.createRef()
        this.weblinksRef = React.createRef()
        this.contactRef = React.createRef()
    }
    render() {
        return (
            <div className="App">
                <Navigation 
                    homeRef={this.homeRef}
                    mogelijkhedenRef={this.mogelijkhedenRef}
                    gerechtenRef={this.gerechtenRef}
                    overMijRef={this.overMijRef}
                    weblinksRef={this.weblinksRef}
                    contactRef={this.contactRef}
                ></Navigation>
                <Home ref={this.homeRef}></Home>
                <Intro></Intro>
                <Mogelijkheden ref={this.mogelijkhedenRef}></Mogelijkheden>
                <ImageContainer imageSource={ImageContainerTafelImage}></ImageContainer>
                <Gerechten ref={this.gerechtenRef}></Gerechten>
                <CulinaireVerwennerij></CulinaireVerwennerij>
                <OverMij ref={this.overMijRef}></OverMij>
                <Weblinks ref={this.weblinksRef}></Weblinks>
                <Contact ref={this.contactRef}></Contact>
                <Footer></Footer>
            </div>
          );
    }
}

export default Main;
