import React from 'react';
import './Home.css';
import huisje from '../../assets/huisje_wit.png';
import logo from '../../assets/1_Thuiskok-logo-tekst.png';

class Home extends React.Component {
    render() {
        return(
            <div className="home page-container" id="home">
                <div className="logo">
                    <div className="logo-container">
                        <img src={huisje} alt="thuiskok Zwaneveld Huis Logo"/>
                        <br className="mobile" />
                        <img className="text" src={logo} alt="thuiskok Zwaneveld Text Logo"/>
                    </div>
                    <div className="bullets">
                        &#8226; Kookworkshop &#8226; Thuisdiner  <br className="mobile" />&#8226; Luxe hapjes <br className="big-screen"/>
                        &#8226; Verjaardagscatering <br className="mobile" />&#8226; High Tea &#8226; Brunch
                    </div>
                        <a className="contact-button" href="#contact">
                            Neem contact op
                        </a>
                </div>
            </div>
        )
    }
}

export default Home;