import React from 'react';
import './contact.css';
import ImageTwitter from '../../assets/9_Twitter.png';
import ImageInstagram from '../../assets/9_Instagram.png';

class Contact extends React.Component {
    render() {
        return(
            <div className="contact-container page-container" id="contact">
                <p className="p-header"><b>Contact</b>gegevens</p>
                <div className="container contactgegevens">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <p><b>thuiskok Zwaneveld</b></p>
                            <p>Dronenpark 14</p>
                            <p>2411 HC Bodegraven</p>
                        </div>
                        <div className="col-12 col-lg-4">
                            <table>
                                <tr>
                                    <td className="big-screen"><p><b>Telefoon</b></p></td>
                                    <td className="small-screen"><p><b>T</b></p></td>
                                    <td className="pl-5 big-screen"><p>0172 - 61 79 81</p></td>
                                    <td className="pl-3 small-screen"><p>0172 - 61 79 81</p></td>
                                </tr>
                                <tr>
                                    <td className="big-screen"><p><b>Mobiel</b></p></td>
                                    <td className="small-screen"><p><b>M</b></p></td>
                                    <td className="pl-5 big-screen"><p>06 - 51 71 08 66</p></td>
                                    <td className="pl-3 small-screen"><p>06 - 51 71 08 66</p></td>
                                </tr>
                                <tr>
                                    <td className="big-screen"><p><b>E-mail</b></p></td>
                                    <td className="small-screen"><p><b>@</b></p></td>
                                    <td className="pl-5 big-screen"><p>caroline@thuiskokzwaneveld.nl</p></td>
                                    <td className="pl-3 small-screen"><p>caroline@thuiskokzwaneveld.nl</p></td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="social-media">
                                <a href="https://twitter.com/kokzwaneveld"><img className="social-media-img" src={ImageTwitter} alt="Twitter"></img></a>
                                <a href="https://www.instagram.com/thuiskokzwaneveld"><img className="social-media-img" src={ImageInstagram} alt="Instagram"></img></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;