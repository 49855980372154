import './App.css';
import Main from './Main';
import React from 'react';
import 'jquery'
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <div className="App">
        <Main></Main>
    </div>
  );
}

export default App;
