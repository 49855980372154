import React from 'react';
import './Mogelijkheden.css';

class MogelijkhedenBigScreen extends React.Component {
    render() {
        return(
            <div className="container mogelijkheden-container">
                <div className="row big-screen">
                    <div className="col-12  col-md-6 col-lg-4">
                        <p className="intro"><b>KOOKWORKSHOP</b></p>
                        <p><b>
                            Wilt u met een groep samen koken en eten? Bijvoorbeeld een drie-gangen diner workshop, seizoensgebonden koken of een ander thema. Of lekkere hapjes maken en daarna gezellig borrelen? Ook dat kan ik voor u verzorgen en een sfeervolle locatie is beschikbaar.    
                        </b></p>
                    </div>
                    <div className="col-12  col-md-6 col-lg-4">
                        <p className="intro"><b>LUXE HAPJES</b></p>
                        <p><b>
                            Wilt u iets anders dan de gebruikelijke toastjes of blokjes kaas? Ik lever luxe hapjes die passen bij elke feestelijke gelegenheid. Vers gemaakt en oogstrelend, uw gasten smullen ervan.
                        </b></p>
                    </div>
                    <div className="col-12  col-md-6 col-lg-4">
                        <p className="intro"><b>HIGH TEA</b></p>
                        <p><b>
                            Een feestelijke en stijlvolle manier om iets te vieren. U kunt bij u thuis of op een door u gekozen locatie genieten van diverse hartige en zoete lekkernijen. Alles huisgemaakt en sfeervol gepresenteerd. Daarbij natuurlijk diverse soorten thee of andere drankjes geserveerd.    
                        </b></p>
                    </div>
                </div>
                <div className="row big-screen">
                    <div className="col-12  col-md-6 col-lg-4">
                        <p className="intro"><b>THUISDINER</b></p>
                        <p><b>
                            Een exclusief idee voor een verjaardag of jubileum of zomaar een gezellige avond. Ik doe de boodschappen, kook, serveer en laat uw keuken opgeruimd achter. In overleg stellen we het menu samen. Koken aan huis bied ik aan in een straal van ± 25 km rond Bodegraven.
                        </b></p>
                    </div>
                    <div className="col-12  col-md-6 col-lg-4">
                        <p className="intro"><b>VERJAARDAGS-CATERING</b></p>
                        <b><p>
                            Ziet u op tegen het vieren van uw verjaardag? Ik neem alle zorgen van u over. Boodschappen doen, koffie schenken, salade met soep en broodjes of alleen hapjes. Alles op maat en bespreekbaar.    
                        </p></b>
                    </div>
                    <div className="col-12  col-md-6 col-lg-4">
                        <p className="intro"><b>BRUNCH</b></p>
                        <p><b>
                            Voor elke gelegenheid kan ik een lunch of brunch voor u verzorgen. Met luxe belegde broodjes, eventueel aangevuld met soep en salade.
                        </b></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default MogelijkhedenBigScreen