import React from 'react';
import './weblinks.css';
import ImageVolksUniversiteit from '../../assets/8_weblink-VolksuniversiteitGroeneHrt.png';
import ImageKeverCabrioRally from '../../assets/8_weblink-KeverCabrioGroeneHart.png'
import ImageBoerensportVerhaar from '../../assets/8_weblink-BoerensportVerhaar_groen.png';
import ImageWelzijnOnzeZorg from '../../assets/8_weblink-WelzijnonzeZorg.png';

class Weblinks extends React.Component {
    render() {
        return(
            <div className="weblinks-container page-container" id="weblinks">
                <p className='p-header'>Weblinks</p>
                <div className="container text-left weblinks-tabel-container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-3">
                            <a href="https://www.vuhetgroenehart.nl/"><img src={ImageVolksUniversiteit} alt="https://www.vuhetgroenehart.nl/"></img></a>
                            <p className='intro'><b><a href="https://www.vuhetgroenehart.nl/">VOLKSUNIVERSITEIT GROENE HART</a></b></p>
                            <p>
                                Voor Volksuniversiteit Het Groene Hart verzorg ik regelmatig kookworkshops, zoals 
                                ‘Koken zonder pakje en zakje’ en ‘Koken met de seizoenen’. Met elkaar koken en tot slot 
                                genieten van het resultaat, onder het genot van een drankje. Een mooie formule!
                            </p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <a href="https://www.rallykever.nl/"><img src={ImageKeverCabrioRally} alt="https://www.rallykever.nl/"></img></a>
                            <p className='intro'><b><a href="https://www.rallykever.nl/">KEVER CABRIO RALLY GROENE HART</a></b></p>
                            <p>
                                Voor evenementenorganisatie Kever Cabrio Rally Groene Hart verzorg ik een kookworkshop. 
                                Deelnemers volgen een prachtige route door het Groene Hart en verzamelen onderweg streekproducten. 
                                Daarmee bereiden ze onder mijn leiding op een boerderij in Nieuwkoop een heerlijke maaltijd.
                            </p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <a href="https://boerensport.nl"><img src={ImageBoerensportVerhaar} alt="https://boerensport.nl"></img></a>
                            <p className='intro'><b><a href="https://boerensport.nl">BOERENSPORT <br className="big-screen"/>VERHAAR</a></b></p>
                            <p>
                                Op de boerderij van Willy Verhaar in Nieuwkoop is een sfeervolle kooklocatie beschikbaar. 
                                Hier maak ik dankbaar gebruik van voor mijn diverse kookworkshops. Een combinatie met andere 
                                activiteiten op de boerderij is mogelijk.
                            </p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <a href="https://www.welzijnonzezorg.nl/"><img src={ImageWelzijnOnzeZorg} alt="https://www.welzijnonzezorg.nl/"></img></a>
                            <p className='intro'><a href="https://www.welzijnonzezorg.nl/"><b>WELZIJN <br className="big-screen"/>ONZE ZORG</b></a></p>
                            <p>
                                Samen met Marlies Schlingmann van Welzijn onze Zorg geef ik de kookworkshop 
                                ‘Pannen voor Mannen’ voor 65-plussers, die het leerzaam en leuk vinden om met elkaar te koken 
                                en te eten. Geen culinaire hoogstandjes maar gewoon lekkere, gezonde maaltijden leren bereiden.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Weblinks;