import React from 'react';
import ImageCulinaireVerwennerijTekst from '../../assets/6_slogan_Culinaire-verwennerij-aan-huis.png';
import ImageCulinaireVerwennerijOfThuis from '../../assets/6_slogan_of-op-locatie.png';
import './culinaire-verwennerij.css';

class CulinaireVerwennerij extends React.Component {
    render() {
        return(
            <div className="culinaire-verwennerij-container" id="culinaire-verwennerij">
                <div className="image-overlay">
                    <img className="tekst-image" src={ImageCulinaireVerwennerijTekst} alt="culinaire verwennerij aan huis"></img>
                    <img className="of-thuis-image" src={ImageCulinaireVerwennerijOfThuis} alt="[of op locatie]"></img>
                </div>
            </div>
        )
    }
}

export default CulinaireVerwennerij;