import React from 'react';
import './gerechten.css';
import ImageVoorgerechten from '../../assets/5-Thuiskok-foto1.jpg';
import ImageHoofdgerechten from '../../assets/5-Thuiskok-foto2.jpg';
import ImageNagerechten from '../../assets/5-Thuiskok-foto3.jpg';

class Gerechten extends React.Component {
    render() {
        return(
            <div className='page-container' id="gerechten">
                <p className='p-header header-red'>Gerechten</p>
                <div className="container gerechten-container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-4">
                            <img className="gerechten-image" src={ImageVoorgerechten} alt="Romige courgettesoep"></img>
                            <p className="intro gerecht"><b>VOORGERECHTEN</b></p>
                            <p><b>Carpaccio</b> van gegrilde courgette met fêta en gedroogde tomaat</p>
                            <p><b>Biologische pompoensoep</b> met tuinkruiden</p>
                            <p><b>Romige courgettesoep</b> met zalmsnippers</p>
                            <p><b>Bieten/appelsalade</b> met forelmousse in een glaasje</p>
                            <p><b>Zalmbonbon</b> gevuld met frisse salade en honingmosterd dressing</p>
                            <p><b>Gevulde vijg</b> met ricotta, walnoot en spek</p>
                            <p><b>Bospaddenstoelenbouillon</b></p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <img className="gerechten-image" src={ImageHoofdgerechten} alt="Runderstoof"></img>
                            <p className="intro gerecht"><b>HOOFDGERECHTEN</b></p>
                            <p><b>Mediterraans vispannetje</b></p>
                            <p><b>Draadjesvlees</b> met biologische rode kool uit de slowcooker</p>
                            <p><b>Varkenshaas</b> met brie en honingsaus</p>
                            <p><b>Gevulde puntpaprika</b> met fêta en kikkererwten</p>
                            <p><b>Runderstoof</b> met paprika, ui en champignons (geserveerd in een stoofpannetje)</p>
                            <p><b>Coq au vin</b> (kip in rode wijn met ui en champignon)</p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <img className="gerechten-image" src={ImageNagerechten} alt="Yoghurt panna-cotta"></img>
                            <p className="intro gerecht"><b>NAGERECHTEN</b></p>
                            <p><b>Stroopwafelparfait</b></p>
                            <p><b>Hangop</b> met gecaramelliseerde appeltjes</p>
                            <p><b>Tarte tatin</b> met biologische vanilleroomijs</p>
                            <p><b>Yoghurt panna-cotta met bosvruchtensaus</b></p>
                            <p><b>Warme crumble</b> van appel en blauwe bes</p>
                            <p><b>Tiramisu</b> in een glaasje</p>
                            <p><b>Peer met notencrumble</b> (uit de oven) met een bolletje kaneelijs of vanille-ijs</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Gerechten;