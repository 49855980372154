import React from 'react';
import './footer.css';

class Footer extends React.Component {
    render() {
        return(
            <div className="footer">
                <p className="copyright">Copyright © {(new Date().getFullYear())} thuiskok Zwaneveld. <br /> All Rights Reserved.</p>
                <p className="luc">Created by Luc Dijkstra</p>
            </div>
        )
    }
}

export default Footer;