import React from 'react';
import './navigation.css';
import NavigationButtonImage from '../../assets/1_Thuiskok-logo-beeldmerkje-rood.png';
class Navigation extends React.Component {
    render() {
        return(
            <nav className="navbar sticky-top navbar-light bg-light navbar-expand-lg">
                <div className="container-fluid">
                    <button 
                        className="navbar-toggler" 
                        type="button" 
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                    >
                        <img src={NavigationButtonImage} alt="Navigation button"/>
                    </button>
                    <div 
                        className="collapse navbar-collapse" 
                        id="navbarNav"
                    >
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="#home">home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#mogelijkheden">mogelijkheden</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#gerechten">gerechten</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#over-mij">over mij</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#weblinks">weblinks</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact">contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navigation;