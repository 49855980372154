import React from 'react';
import './over-mij.css';
import OverMijImage from '../../assets/7-Thuiskok-fotoCaroline.jpg';

class OverMij extends React.Component {
    render() {
        return(
            <div className="over-mij-container page-container" id="over-mij">
                <p className='p-header header-red'>Over <b>mij</b></p>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <p className="intro zalf-naar-saus"><b>VAN ZALF NAAR SAUS....</b></p>
                            <p>
                                Ruim vijfentwintig jaar was ik werkzaam in de gezondheidszorg als ziekenhuis-apothekersassistente. 
                                Toen begon het te kriebelen om iets heel anders te gaan doen. Omdat koken altijd al een grote hobby was, ben 
                                ik gestart met de koksopleiding. Eén dag per week weer naar school, een combinatie van praktijk en theorie. 
                                Het bereiden van een saus heeft veel raakvlakken met het maken van een zalf of crème, alleen die proef je niet! 
                                Het behaalde diploma was de kroon op mijn werk.
                            </p>
                            <p>
                                Nu was ik kok, maar zonder ervaring. Daarom was ik blij dat ik bij Hotel Restaurant Rust Even kon werken om ervaring 
                                op te doen. Dit heb ik twee jaar naast mijn ziekenhuiswerk gedaan en toen was het moment daar voor een nieuwe uitdaging: 
                                mijn eigen bedrijf.
                            </p>
                            <p>
                                Eenvoudig, smaakvol en duurzaam koken heeft mijn voorkeur. Daarom maak ik veelal gebruik van biologische en 
                                seizoensgebonden producten zonder kunstmatige toevoegingen, want dat is de basis voor een pure smaak! Ik wil het u graag 
                                laten proeven, hopelijk tot ziens in uw keuken of op locatie!
                            </p>
                            <p><b>Caroline Zwaneveld</b></p>
                        </div>
                        <div className="col-12 col-lg-4 over-mij-image-col">
                            <img className="over-mij-image" src={OverMijImage} alt="Caroline Zwaneveld"></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OverMij;