import React from 'react';
import './image-container.css';

class ImageContainer extends React.Component {
    render() {
        return(
            <img className="page-image" src={this.props.imageSource} alt="afbeelding"></img>
        )
    }
}

export default ImageContainer;